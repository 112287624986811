<template>
  <AppLayout>
    <div class="project-list">
      <b-row v-for="(chunk, index) in projectChunks" :key="index">
        <b-col md="6" class="mt-3 mt-md-4" v-for="project in chunk" :key="project.id">
          <ProjectCard :project="project" />
        </b-col>
      </b-row>
    </div>

    <template v-slot:actionbar>
      <div class="d-flex align-items-center">
        <TenantLogo />

        <div class="ml-3">
          <h3 class="title">{{$t('lang.welcome.msg')}}</h3>
          <p class="subtitle">{{$t('lang.select_project.msg')}}</p>
          
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<script>
import { mapState } from 'vuex';
import _chunk from 'lodash/chunk';
import store from '@/store';
import ProjectCard from '@/components/ProjectCard.vue';
import TenantLogo from '@/components/TenantLogo.vue';

export default {
  components: { ProjectCard, TenantLogo },
  props: {
    page: { type: Number, required: true },
  },
  computed: {
    hasNextPage() {
      return this.projectsTotal > this.page * this.perPage;
    },
    projectChunks() {
      return _chunk(this.projects, 2);
    },
    ...mapState('project', ['projects', 'projectsTotal', 'perPage']),
  },

  beforeRouteEnter(to, from, next) {
    const currentPage = parseInt(to.query.page, 10) || 1;
    to.params.page = currentPage;

    if (store.state.project.projects.length === 1) {
      next({ name: 'project-home', params: { id: store.state.project.projects[0].id } });
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    const currentPage = parseInt(to.query.page, 10) || 1;
    to.params.page = currentPage;

    if (this.projects.length <= 1) {
      next({ name: 'project-home', params: { id: this.projects[0].id } });
    } else {
      next();
    }
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.project-list {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  overflow-y: scroll;
  height: 100%;

  @include media-breakpoint-up(md) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.title {
  font-size: 1.25rem;
  color: #242424;
}
.subtitle {
  color: #6b6b6b;
}
</style>
