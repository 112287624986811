var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AppLayout",
    {
      scopedSlots: _vm._u([
        {
          key: "actionbar",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "d-flex align-items-center" },
                [
                  _c("TenantLogo"),
                  _c("div", { staticClass: "ml-3" }, [
                    _c("h3", { staticClass: "title" }, [
                      _vm._v(_vm._s(_vm.$t("lang.welcome.msg")))
                    ]),
                    _c("p", { staticClass: "subtitle" }, [
                      _vm._v(_vm._s(_vm.$t("lang.select_project.msg")))
                    ])
                  ])
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "project-list" },
        _vm._l(_vm.projectChunks, function(chunk, index) {
          return _c(
            "b-row",
            { key: index },
            _vm._l(chunk, function(project) {
              return _c(
                "b-col",
                {
                  key: project.id,
                  staticClass: "mt-3 mt-md-4",
                  attrs: { md: "6" }
                },
                [_c("ProjectCard", { attrs: { project: project } })],
                1
              )
            }),
            1
          )
        }),
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }