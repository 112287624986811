<template>
  <div class="project-card" @click="visitProject">
    <img
      class="photo"
      :src="project.image_cover_url ? project.image_cover_url : '/img/photo-placeholder.jpg'"
      :alt="project.name"
    />

    <div class="d-flex align-items-center" style="height: 60px;">
      <Logo :image-url="logoUrl" :description="project.name" :height="60" :width="60" />

      <div class="ml-2 flex-grow-1">
        <h3 class="title">{{ project.name }}</h3>
        <p class="subtitle">{{ project.cameras.length }} {{$t('lang.camera.msg')}}</p>
      </div>

      <button class="btn-circle mb-1 button mx-3">
        <i class="tmlps-icn-next h2 m-0"></i>
      </button>
    </div>
  </div>
</template>

<script>
import config from '@/config';
import Logo from '@/components/Logo';

export default {
  name: 'ProjectCard',
  components: {
    Logo,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  computed: {
    logoUrl() {
      return this.project.logo_url ?? config.PROJECT_LOGO_PLACEHOLDER;
    },
  },
  methods: {
    visitProject() {
      this.$router.push({
        name: 'project-home',
        params: { id: this.project.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

.project-card {
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.project-card:hover {
  cursor: pointer;
  box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.2);
}

.photo {
  height: 100px;
  width: 100%;
  object-fit: cover;
  object-position: center;

  @include media-breakpoint-up(md) {
    height: 23.5rem;
  }
}

.title {
  font-size: 1.25rem;
  color: #242424;
}

.subtitle {
  color: #6b6b6b;
}

.button {
  height: 2.5rem;
  width: 2.5rem;
  background-color: white;
  border-radius: 100%;
  border: 1px solid var(--primary);
  color: #242424;
  outline: none;
}
.project-card:hover .button {
  background-color: var(--primary);
  color: white;
}
</style>
