import { render, staticRenderFns } from "./ProjectList.vue?vue&type=template&id=4787cf8d&scoped=true&"
import script from "./ProjectList.vue?vue&type=script&lang=js&"
export * from "./ProjectList.vue?vue&type=script&lang=js&"
import style0 from "./ProjectList.vue?vue&type=style&index=0&id=4787cf8d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4787cf8d",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/marcosalazar/Documents/HoneyMustard/Timelapse/timelapse-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4787cf8d')) {
      api.createRecord('4787cf8d', component.options)
    } else {
      api.reload('4787cf8d', component.options)
    }
    module.hot.accept("./ProjectList.vue?vue&type=template&id=4787cf8d&scoped=true&", function () {
      api.rerender('4787cf8d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ProjectList.vue"
export default component.exports