var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-card", on: { click: _vm.visitProject } },
    [
      _c("img", {
        staticClass: "photo",
        attrs: {
          src: _vm.project.image_cover_url
            ? _vm.project.image_cover_url
            : "/img/photo-placeholder.jpg",
          alt: _vm.project.name
        }
      }),
      _c(
        "div",
        {
          staticClass: "d-flex align-items-center",
          staticStyle: { height: "60px" }
        },
        [
          _c("Logo", {
            attrs: {
              "image-url": _vm.logoUrl,
              description: _vm.project.name,
              height: 60,
              width: 60
            }
          }),
          _c("div", { staticClass: "ml-2 flex-grow-1" }, [
            _c("h3", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.project.name))
            ]),
            _c("p", { staticClass: "subtitle" }, [
              _vm._v(
                _vm._s(_vm.project.cameras.length) +
                  " " +
                  _vm._s(_vm.$t("lang.camera.msg"))
              )
            ])
          ]),
          _vm._m(0)
        ],
        1
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "btn-circle mb-1 button mx-3" }, [
      _c("i", { staticClass: "tmlps-icn-next h2 m-0" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }